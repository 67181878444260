<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.achievements.linkPartnership.title') }}</p>
    </template>

    <div class="panel">
      <div class="text-lg font-semibold">{{ $t('companyArea.achievements.linkPartnership.title') }}</div>
      <div class="border shadow rounded bg-yellow-100 p-4">{{ $t('companyArea.achievements.linkPartnership.shortVersion') }} </div>
      <div class="mt-4">{{ $t('companyArea.achievements.linkPartnership.currentStatus') }} <span v-if="companyProfile.link_partner_at" class="font-semibold text-green-600">{{ $t('companyArea.achievements.linkPartnership.linkFound') }}</span><span v-else class="font-semibold text-red-600">{{ $t('companyArea.achievements.linkPartnership.noLinkFound') }}</span></div>
      <div class="mt-2">{{ $t('companyArea.achievements.linkPartnership.pressButton') }}</div>
      <button class="btn btn-sm btn-primary mt-3" @click="checkLink">{{ $t('companyArea.achievements.linkPartnership.findLink') }}</button>
      <div v-if="!linkFound" class="text-red-600 text-lg my-5 font-semibold">
        {{ $t('companyArea.achievements.linkPartnership.noLinkTitle') }}<br>
        <span class="text-black font-normal text-base">{{ $t('companyArea.achievements.linkPartnership.noLinkText') }}</span>
      </div>

      <div v-if="!company.websites[0].url" class="my-3 border border-gray-200 shadow-md p-4">
        <div class="font-semibold">{{ $t('companyArea.achievements.linkPartnership.noWebsite') }}</div>
        <div class="mb-3">{{ $t('companyArea.achievements.linkPartnership.noWebsiteText') }}</div>
        <a href="https://webdesire.ch" class="btn btn-sm btn-secondary mt-3">Webdesire</a>
      </div>
      <div class="mt-8 mb-4">
        {{ $t('companyArea.achievements.linkPartnership.linkPartnerExplanation') }}
      </div>

      <div class="border shadow rounded bg-yellow-100 p-4">
        <div class="text-base font-semibold mt-4">{{ $t('companyArea.achievements.linkPartnership.benefits') }}</div>
        <div class="text-sm font-semibold mt-4">{{ $t('companyArea.achievements.linkPartnership.benefitFreeRequests') }}</div>
        <ul class="my-2 list-disc pl-5">
          <li v-html="$t('companyArea.achievements.linkPartnership.benefit5')"></li>
          <li v-html="$t('companyArea.achievements.linkPartnership.benefit3', { link: 'https://top-offerten.ch' + companyProfile.uri})"></li>
          <!--        <li>Sie können Ihre Firma mit Bildern und Text auf dem Bewertungsportfolio präsentieren</li>-->
        </ul>
        <div class="text-sm font-semibold mt-4">{{ $t('companyArea.achievements.linkPartnership.benefitPresentation') }}</div>
        <ul class="my-2 list-disc pl-5">
          <li>{{ $t('companyArea.achievements.linkPartnership.benefit4') }}</li>
          <li>{{ $t('companyArea.achievements.linkPartnership.benefit1') }}</li>
          <li v-html="$t('companyArea.achievements.linkPartnership.benefit2', { link: 'https://top-offerten.ch' + companyProfile.uri})"></li>
          <!--        <li>Sie können Ihre Firma mit Bildern und Text auf dem Bewertungsportfolio präsentieren</li>-->
        </ul>
      </div>


      <div class="text-sm font-semibold mt-4">{{ $t('companyArea.achievements.linkPartnership.becomingLinkPartner') }}</div>
      <ul class="my-2 list-disc pl-5">
        <li>{{ $t('companyArea.achievements.linkPartnership.step1') }} <strong>{{ companyProfile.link }}</strong></li>
        <li>{{ $t('companyArea.achievements.linkPartnership.step2') }} <strong>{{ company.websites[0].url }}</strong></li>
        <li v-html="$t('companyArea.achievements.linkPartnership.step3')"></li>
        <li>{{ $t('companyArea.achievements.linkPartnership.step4') }}</li>
      </ul>

      <widget></widget>
      <div v-if="companyProfile.link_partner_at" class="text-green-600 text-lg mt-5 font-semibold">
        {{ $t('companyArea.achievements.linkPartnership.linkFound') }}
      </div>
      <div v-else>
        <div class="mt-5">{{ $t('companyArea.achievements.linkPartnership.linkAlreadyPlaced') }}</div>
        <button class="btn btn-sm btn-primary mt-3" @click="checkLink">{{ $t('companyArea.achievements.linkPartnership.findLink') }}</button>
        <div v-if="!linkFound" class="text-red-600 text-lg mt-5 font-semibold">
          {{ $t('companyArea.achievements.linkPartnership.noLinkTitle') }}
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
  import Layout from '../../Layout.vue';
  import companyApi from '../../connections/company';
  import store from '../../store/mainStore';
  import Widget from './Widget.vue';

  export default {
    name: 'LinkPartnership',
    components: {
      Layout,
      Widget
    },

    data() {
      return {
        linkFound: 'noSearch',
      };
    },
    computed: {
      companyProfile() {
        return store.state.company.companyProfile;
      },
      company() {
        return store.state.company.company;
      },

    },
    created() {
      if (!this.companyProfile.hasOwnProperty('id')) {
        companyApi.get('/companies/' + this.company.id + '/company-profile', { notification: false })
          .then(response => store.commit('company/setCompanyProfile', response.data.content.companyProfile))
          .catch();
      }
    },

    methods: {
      checkLink() {
        this.linkFound = 'noSearch';
        companyApi.get('/companies/' + this.company.id + '/company-profile/' + this.companyProfile.id + '/link-check', { notification: true, progress: true})
          .then(response => {
            if (response.data.notificationType === 'success') {
              store.commit('company/setCompanyProfile', response.data.content.companyProfile);
              this.linkFound = true;
            } else {
              this.linkFound = false;
            }
          })
          .catch();
      },
    },
  };
</script>

<style scoped>

</style>
