<template>
  <div class="p-4 rounded mb-4 border bg-gray-100">
    <div class="flex justify-between font-semibold">
      <div>{{ employee.user.surname }} {{ employee.user.name }} <span v-if="employee.is_admin">(Administrator)</span></div>
      <div v-if="isAdmin" class="flex">
        <circle-button :icon="['fal','trash-alt']"
                       size="sm"
                       class="mr-2"
                       @click="show.modal = true"
        />
        <circle-button :icon="['fal','pen']" size="sm" @click="show.details = ! show.details" />
      </div>
    </div>
    <div v-if="show.details" class="mt-3 mb-3">
      <input v-model="formdata.company_email" type="text" class="border border-gray-400 w-full mb-3">

      <button v-if="formdata.company_email !== employee.company_email" class="btn btn-sm btn-primary mr-2" @click="updateEmail">
        {{ $t('companyArea.settings.snippets.employeesListed.saveEmail') }}
      </button>

      <button v-if="employees.length > 1" class="btn btn-sm mr-2" :class="show.policies ? 'btn-primary' : 'btn-secondary'" @click="show.policies = ! show.policies; show.distribution = false">
        {{ $t('companyArea.settings.snippets.employeesListed.userRights') }}
      </button>
      <button v-if="employees.length > 1" class="btn btn-sm" :class="show.distribution ? 'btn-primary' : 'btn-secondary'" @click="show.distribution = ! show.distribution; show.policies = false">
        {{ $t('companyArea.settings.snippets.employeesListed.leadSending') }}
      </button>

      <div v-if="show.policies && employees.length > 1">
        <div class="my-3 text-gray-800">{{ $t('companyArea.settings.employees.policiesInfo') }}</div>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="item.is_admin"
                 type="checkbox"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
                 @change="updateRights"
          >
          <span class="ml-2">{{ $t('companyArea.settings.snippets.employeesListed.administrator') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="item.buy_leads"
                 type="checkbox"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
                 @change="updateRights"
          >
          <span class="ml-2">{{ $t('companyArea.settings.snippets.employeesListed.buyLeads') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="item.leads"
                 type="checkbox"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
                 @change="updateRights"
          >
          <span class="ml-2">{{ $t('companyArea.settings.snippets.employeesListed.manageLeads') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="item.credit"
                 type="checkbox"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
                 @change="updateRights"
          >
          <span class="ml-2">{{ $t('companyArea.settings.snippets.employeesListed.manageSaldo') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="item.reviews"
                 type="checkbox"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
                 @change="updateRights"
          >
          <span class="ml-2">{{ $t('companyArea.settings.snippets.employeesListed.manageReviews') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="item.profile"
                 type="checkbox"
                 class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400"
                 @change="updateRights"
          >
          <span class="ml-2">{{ $t('companyArea.settings.snippets.employeesListed.manageProfile') }}</span>
        </label>
      </div>
      <div v-if="show.distribution">
        <div class="my-3 text-gray-800">{{ $t('companyArea.settings.employees.distrubutionInfo') }}</div>
        <div v-for="location in locations" class="d-flex flex-column">
          <div class="my-3 bg-gray-200 border p-2 rounded">
            <button v-if="locations.length > 1"
                    variant="link"
                    @click="addLocation(location.id)"
            >
              {{ location.city }}
            </button>
            <div v-if="formdata.locations.includes(location.id) || locations.length === 1">
              <button class="btn btn-sm mr-1"
                      :class="active(location.id, null) ? 'btn-green' : 'btn-secondary'"
                      @click="addRecipientFilter(location.id)"
              >
                {{ $t('companyArea.settings.snippets.employeesListed.locationMasterUser') }}
              </button>
              <button v-for="classification in classifications"
                      class="btn btn-sm mr-1 mt-1"
                      :class="active(location.id, classification.number) ? 'btn-green' : 'btn-secondary'"
                      @click="addRecipientFilter(location.id, classification.number)"
              >
                {{ classification.name }}
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <confirmation-modal v-if="show.modal"
                      :confirmation="'Möchten Sie den Benutzer ' + employee.user.surname + ' ' + employee.user.name +' löschen?'"
                      @close="show.modal = false"
                      @action="deleteEmployee"
  />
</template>

<script>
import notification from '../../../connections/notification';
import authApi from '../../../connections/auth';
import store from '../../../store/mainStore';
import CircleButton from '../../../snippets/CircleButton.vue';
import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';

export default {
  name: 'EmployeesListed',
  components: { ConfirmationModal, CircleButton },
  props: {
    employees: Array,
    employee: Object,
    isAdmin: Boolean,
    leadRecipientFilters: Array,
  },
  emits: ['update-employee', 'delete'],
  data() {
    return {
      show: {
        details: true,
        policies: false,
        distribution: false,
        modal: false,
      },
      item: this.employee,
      locations: store.state.company.locations,
      classifications: store.state.company.classifications,
      formdata: {
        locations: [],
        company_email: this.employee.company_email
      },
    };
  },
  computed: {},
  watch: {
    employee: {
      handler(newVal) {
        this.item = newVal;
      }, deep: true,
    },
  },

  methods: {
    deleteEmployee() {
      this.$emit('delete', this.employee.id);
    },
    updateRights() {
      this.$emit('update-employee', this.item);
    },
    updateEmail() {
      this.item.company_email = this.formdata.company_email
      this.$emit('update-employee', this.item);
    },
    addLocation(locationId) {
      if(! this.formdata.locations.includes(locationId)) {
        this.formdata.locations.push(locationId);
      } else {
        this.formdata.locations.filter(item => item !== locationId);
      }
    },

    addRecipientFilter(locationId, number = null) {
      if(this.leadRecipientFilters.find(item => item.employee_id === this.employee.id && item.location_id === locationId && item.classification_number === number)) {
        let index = this.leadRecipientFilters.findIndex(item => item.employee_id === this.employee.id && item.location_id === locationId && item.classification_number === number);
        if(this.leadRecipientFilters[index].deleted_at === null) {
          this.leadRecipientFilters[index].deleted_at = new Date();
          // this.$set(this.leadRecipientFilters[index], 'deleted_at', new Date());
        } else {
          this.saveOrUpdateIfNotInUse(locationId, number, index);
        }
      } else {
        this.saveOrUpdateIfNotInUse(locationId, number);
      }
    },
    saveOrUpdateIfNotInUse(locationId, number, index = null) {
      let leadRecipientFilter = this.leadRecipientFilters.filter(
          item => item.employee_id !== this.employee.id && item.location_id === locationId && item.classification_number === number && item.deleted_at === null);
      if(leadRecipientFilter.length === 0) {
        if(index) {
          this.leadRecipientFilters[index].deleted_at = null;
          // this.$set(this.leadRecipientFilters[index], 'deleted_at', null);
        } else {
          this.leadRecipientFilters.push({ company_id: this.locations[0].company_id, employee_id: this.employee.id, location_id: locationId, classification_number: number, deleted_at: null });
        }
      } else {
        let employee = this.employees.find(item => item.id === leadRecipientFilter[0].employee_id);
        notification.warning( this.$t('companyArea.settings.employees.duplicateEmployee', { name: employee.user.name, surname: employee.user.surname }));
      }

    },
    active(locationId, number) {
      return this.leadRecipientFilters.find(item => item.employee_id === this.employee.id && item.location_id === locationId && item.classification_number === number && item.deleted_at === null)
          ? true
          : false;
    },
  },
};
</script>

<style scoped>

</style>
