<template>
  <div class="font-semibold">{{ subline() }}</div>
  <div class="text-sm text-gray-500">{{ $t('general.leadDate') }} {{ this.dayjsHelper.showDateTime(companyLead.lead.valid_at) }}</div>
  <div class="py-1">
    <a :href="'https://www.google.com/maps/search/?api=1&query=' + companyLead.lead.address + '+' + companyLead.lead.postcode + '+' + companyLead.lead.city" target="_blank">
      {{ companyLead.lead.postcode }}
      {{ companyLead.lead.city }}
    </a>
  </div>
  <div v-if="companyLead.lead.phone" class="py-1">
    {{ companyLead.lead.phone }}
  </div>
  <div v-if="companyLead.lead.email !== 'deleted@deleted'" class="py-1">
    {{ emailVerified ? $t('form.emailVerified') : $t('form.emailNotYetVerified') }}
  </div>

  <div class="flex flex-col">
    <div>
      <button class="btn btn-lg btn-primary mt-3" :data-cy="'buy-lead-' + companyLead.lead_id" @click="show.confirmation[companyLead.id] = true">
        {{ $t('companyArea.leads.leadOverview.cards.newLeadsCard.buyLead', { currency: companyLead.lead.form.currency, price: price(companyLead) }) }}
      </button>
    </div>
    <div v-if="companyLead.lead.desired_quotes > 3 && (companyLead.lead.desired_quotes - companyLead.lead.taken_by) > 1" class="text-xs sm:text-sm text-gray-400 mt-3 pr-7 sm:pr-0">{{ $t('companyArea.leads.leadOverview.cards.newLeadsCard.endpriceInfo', { currency: companyLead.lead.form.currency, price: price(companyLead, false, true), desired_quotes: companyLead.lead.desired_quotes }) }}</div>
    <div v-if="company.prepaid && parseFloat(company.credit) < price(companyLead)" class="text-xs sm:text-sm text-gray-400 mt-3 pr-7 sm:pr-0">{{ $t('companyArea.accounting.credit.feeInfos', { feePercent: feeInfo()[0], currency: companyLead.lead.form.currency, feeFix: feeInfo()[1] }) }}</div>
    <div v-if="companyLead.bail"
         class="text-xs sm:text-sm mt-3 pr-7 sm:pr-0"
         v-html="$t('companyArea.leads.leadOverview.cards.newLeadsCard.priceDepositInfo', { currency: companyLead.lead.form.currency, price: price(companyLead, false), deposit: companyLead.bail, appName: appName })"
    />
    <div v-if="companyLead.lead.commission" class="mt-2">
      {{ $t('companyArea.leads.leadOverview.cards.newLeadsCard.commissionInfo', { commission: companyLead.lead.commission }) }}
    </div>
    <div v-else-if="vipany" class="mt-2">
      {{ $t('companyArea.leads.leadOverview.cards.newLeadsCard.withoutProvision') }}
    </div>
  </div>

  <div v-if="show.confirmation[companyLead.id]" class="absolute flex justify-center inset-0 bg-white rounded z-50 py-3 px-4">
    <div>
      <div class="text-center text-base font-semibold -pt-2">{{ companyLead.lead.city }}</div>
      <div class="text-center text-xs text-gray-500 font-light pb-2">{{ $t('classification.' + companyLead.lead.form.translation_key) }} - {{ this.dayjsHelper.showDate(companyLead.date) }}</div>

      <div class="text-center font-semibold px-2" v-html="confirmationStatus(companyLead)[1]" />
      <div v-if="confirmationStatus(companyLead)[0]" class="flex justify-center mt-3">
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-black bg-black text-white rounded-full hover:bg-white hover:text-black mb-4 mr-3"
                data-cy="confirm"
                @click="showSpinner ? '' : takeLead()"
        >
          <font-awesome-icon :icon="['fal','check']" />
          <span class="absolute -bottom-6 text-black text-xs uppercase">{{ $t('general.yes') }}</span>
        </button>
        <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-black text-black rounded-full hover:bg-black text-black hover:text-white mb-4 ml-3"
                data-cy="reject"
                @click="show.confirmation[companyLead.id] = false"
        >
          <font-awesome-icon :icon="['fal','times']" />
          <span class="absolute -bottom-6 text-black text-xs uppercase">{{ $t('general.no') }}</span>
        </button>
      </div>
      <div v-else class="flex justify-center mt-3">
        <button class="btn btn-sm btn-secondary" @click="show.confirmation[companyLead.id] = false">{{ $t('general.close') }}</button>
      </div>
      <div class="text-center mt-16">
    <router-link to="Credit" class=" text-blue-500 cursor-pointer hover:text-blue-300">{{ $t('companyArea.leads.leadOverview.cards.newLeadsCard.editPaymentMethod') }}</router-link>
      </div>
    </div>
  </div>
  <spinner v-if="showSpinner" style="z-index: 9999;" />
</template>

<script>
  import store from '../../../../store/mainStore';
  import leadFormApi from '../../../../connections/leadForm';
  import Spinner from '../../../../snippets/Spinner.vue';

  export default {
    name: 'NewLeadsCard',
    components: { Spinner },
    props: {
      companyLead: Object,
      fields: Array,
    },
    emits: ['account-blocked', 'evaluatePaymentMethod', 'leadTaken'],
    data() {
      return {
        show: {
          confirmation: [],
        },
        vipany: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'vipany',
        showSpinner: false,
        bought: false,
      };
    },
    computed: {
      userRole() {
        return store.getters['company/userRole'];
      },
      company() {
        return store.state.company.company;
      },
    },
    methods: {
      feeInfo () {
        if (this.company.stripe_payment_methods.length > 0) {
          return [2.9, 0.30];
        } else {
          return [1.3, 0.00];
        }
      },
      price(companyLead, withBail = true, lowestPrice = false) {
        if(companyLead.price_sale) {
          return companyLead.price_sale
        }

        let price = companyLead.lead.prices[lowestPrice ? companyLead.lead.desired_quotes : companyLead.lead.taken_by + 1];
        if (withBail && companyLead.bail) {
          return (parseFloat(price) + parseFloat(companyLead.bail)).toFixed(2);
        }
        return price;
      },
      endpriceWithFees(companyLead, withBail = true) {
        let price = this.price(companyLead, withBail);
        let bail = null;
        let fee = 0;
        if (withBail && companyLead.bail) {
          price = (parseFloat(price) + parseFloat(companyLead.bail)).toFixed(2);
          bail = parseFloat(companyLead.bail);
        }
        if (this.company.prepaid && parseFloat(this.company.credit) < price) {
          // Stripe card is only used silently when the card is set to main
          if (this.company.stripe_payment_methods.length > 0 && this.company.stripe_payment_methods.filter(obj => obj.main === 1).length > 0) {
            fee = (parseFloat(price) * 0.029 + parseFloat(0.30)).toFixed(2);
          } else {
            fee = (parseFloat(price) * 0.013).toFixed(2);
          }
        }
        price = (parseFloat(price) + parseFloat(fee)).toFixed(2);
        return [price, fee, bail];
      },
      confirmationStatus(companyLead) {
        let message = '';
        let status = false;
        if (![2, 3, 4, 8].includes(store.state.company.company.status) && (this.userRole.is_admin || this.userRole.buy_leads)) {
          status = true;
          if (companyLead.bail) {
            let endpriceWithFees = this.endpriceWithFees(companyLead, true);
            message = this.$t('companyArea.leads.leadOverview.cards.newLeadsCard.buyWithEndpriceMessageWithBail', {
              currency: companyLead.lead.form.currency,
              endprice: endpriceWithFees[0],
              fee: endpriceWithFees[1],
              bail: endpriceWithFees[2],
              appName: this.appName,
            });
          } else {
            let endpriceWithFees = this.endpriceWithFees(companyLead, false);
            if (endpriceWithFees[1] === 0) {
              message = this.$t('companyArea.leads.leadOverview.cards.newLeadsCard.buyWithEndpriceMessage', {
                currency: companyLead.lead.form.currency,
                endprice: endpriceWithFees[0],
              });
            } else {
              message = this.$t('companyArea.leads.leadOverview.cards.newLeadsCard.buyWithEndpriceMessageWithFee', {
                currency: companyLead.lead.form.currency,
                endprice: endpriceWithFees[0],
                fee: endpriceWithFees[1],
              });
            }
          }
        } else {
          if (store.state.company.company.status !== 2) {
            this.$emit('account-blocked');
          }
          message = this.$t('companyArea.leads.leadOverview.cards.newLeadsCard.notAuthorized');
        }
        return [status, message];
      },
      takeLead() {
        this.showSpinner = true;
        leadFormApi.patch('/companies/' + this.companyLead.company_id + '/leads/' + this.companyLead.lead.id, { returnBaseUri: window.location.pathname }, { 'progress': false, notification: false })
          .then(response => this.evaluateTakeLeadResponse(response))
          .catch(error => this.showSpinner = false);
      },
      emailVerified() {
        if (this.companyLead.lead.hasOwnProperty('user') && this.companyLead.lead.user.email_verified_at !== null) {
          return true;
        } else {
          return false;
        }
      },
      subline() {
        if (this.fields.find(obj => obj.usp && obj.static === 0) && !this.bought) {
          let form = JSON.parse(this.companyLead.lead.dynamic_form);
          return this.fields.find(obj => obj.id === form[this.fields.find(obj => obj.usp && obj.static === 0).id]).label + ' - ' + this.dayjsHelper.showDate(this.companyLead.date);
        } else {
          return this.$t('general.jobDate') + ' ' + this.dayjsHelper.showDate(this.companyLead.date);
        }
      },
      evaluatePaymentMethod(content, notification) {
        this.showSpinner = false;
        this.$emit('evaluatePaymentMethod', content, notification);
      },
      evaluateTakeLeadResponse(response){
        this.showSpinner = false
        this.bought = true
        if (response.data.notificationType === 'success') {
          if (response.data.content.url) {
            // We get a url from the backend if the use has to pay with postfinance
            this.evaluatePaymentMethod(response.data.content, response.data.notification);
          } else {
            this.$emit('leadTaken', { companyLead: response.data.content.companyLead, companyCredit: response.data.content.company.credit });
          }
        }
      }
    },
  };
</script>

<style scoped>

</style>
