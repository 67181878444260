<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.employees.userManagement') }}</p>
    </template>

    <template v-slot:filters>
      <div class="flex flex-col sm:flex-row justify-center px-3 mt-8 mb-4 sm:mb-0">
        <button class="rounded-full h-10 bg-white hover:bg-gray-200 border-none shadow px-4 m-1" @click="show.addEmployee = !show.addEmployee">{{ $t('companyArea.settings.employees.addUser') }}</button>
      </div>
    </template>

    <div class="panel">
      <employees-listed v-for="employee in employees"
                        :key="'companyUser' + employee.id"
                        v-model:employees="employees"
                        :employee="employee"
                        :is-admin="isAdmin"
                        :lead-recipient-filters="filters.leadRecipientFilters"
                        @delete="deleteEmployee"
                        @update-employee="updateEmployee"
      />
      <button v-if="changesDetected" class="btn btn-lg btn-primary" @click="updateChanges">{{ $t('general.save') }}</button>
    </div>

    <confirmation-modal v-if="show.addEmployee" @close="show.addEmployee = false">
      <input id="AddEmployee"
             v-model="formdata.email"
             type="text"
             :placeholder="$t('companyArea.settings.employees.userEmail')"
             class="border border-gray-400"
             @keyup.enter="addUser"
      >
      <input v-if="show.createUser"
             id="name"
             v-model="formdata.name"
             type="text"
             :placeholder="$t('companyArea.settings.employees.firstName')"
             class="border border-gray-400 mt-2"
      >
      <input v-if="show.createUser"
             id="surname"
             v-model="formdata.surname"
             type="text"
             :placeholder="$t('companyArea.settings.employees.lastName')"
             class="border border-gray-400 mt-2"
      >
      <input v-if="show.createUser"
             id="password"
             v-model="formdata.password"
             type="password"
             :placeholder="$t('companyArea.settings.employees.password')"
             class="border border-gray-400 mt-2"
      >
      <div class="mt-3 mb-3">
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="formdata.is_admin" type="checkbox" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">{{ $t('companyArea.settings.employees.administrator') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="formdata.buy_leads" type="checkbox" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">{{ $t('companyArea.settings.employees.buyLead') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="formdata.leads" type="checkbox" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">{{ $t('companyArea.settings.employees.manageLead') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="formdata.credit" type="checkbox" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">{{ $t('companyArea.settings.employees.manageSaldo') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="formdata.reviews" type="checkbox" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">{{ $t('companyArea.settings.employees.manageReviews') }}</span>
        </label>
        <label class="inline-flex items-center mr-5 mt-3">
          <input v-model="formdata.profile" type="checkbox" class="form-checkbox h-7 w-7 border border-gray-400 rounded bg-white text-yellow-400">
          <span class="ml-2">{{ $t('companyArea.settings.employees.manageProfile') }}</span>
        </label>
      </div>
      <div class="flex justify-center mt-3">
        <button class="btn btn-lg btn-primary" @click="addUser">{{ $t('companyArea.settings.employees.add') }}</button>
      </div>
    </confirmation-modal>
  </layout>
</template>

<script>
import store from '../../store/mainStore';
import authApi from '../../connections/auth';
import Layout from '../../Layout.vue';
import EmployeesListed from './snippets/EmployeesListed.vue';
import ConfirmationModal from '../../snippets/ConfirmationModal.vue';

export default {
  name: 'Employees',
  components: { ConfirmationModal, EmployeesListed, Layout },
  data() {
    return {
      employees: [],
      filters: {
        leadRecipientFilters: [],
      },
      show: {
        addEmployee: false,
        createUser: false,
      },
      changesDetected: false,
      initial: true,
      formdata: {
        email: '',
        name: '',
        surname: '',
        password: '',
        company_id: store.state.company.company.id,
        company: store.state.company.company.name,
        is_admin: false,
        buy_leads: false,
        leads: false,
        credit: false,
        reviews: false,
        profile: false,
      },
    };
  },
  computed: {
    isAdmin() {
      return store.getters['company/userRole'].is_admin;
    },
  },
  watch: {
    filters: {
      handler() {
        this.initial ? this.initial = false : this.changesDetected = true;
      }, deep: true,
    },
  },
  created() {
    authApi.get('/employees/companies/' + store.state.company.company.id + '/', { notification: false }).
        then(response => [this.employees = response.data.content.employees, this.filters.leadRecipientFilters = response.data.content.leadRecipientFilters]);
  },
  methods: {
    addUser() {
      let that = this;
      authApi.post('employees/', that.formdata, { 'progress': false, notification: true }).
          then(response => response.data.notificationType === 'success' ? [that.employees = response.data.content, that.show.addEmployee = false] : response.data.content === 'create'
              ? that.show.createUser = true
              : '');
    },
    updateChanges() {
      authApi.patch('companies/' + this.formdata.company_id + '/employees/filters', this.filters, { 'progress': false, notification: true }).
          then(response => [this.filters.leadRecipientFilters = response.data.content, this.initial = true, this.changesDetected = false]);

    },
    updateEmployee(employee) {
      authApi.patch('employees/' + employee.id, employee, { 'progress': true, notification: true }).
          then(response => this.employees = response.data.content);
    },
    deleteEmployee(employee_id) {
      authApi.delete('employees/' + employee_id, { 'progress': true, notification: true }).
          then(response => [response.data.notificationType === 'success' ? this.employees = response.data.content : '']);
    },
  },
};
</script>

<style scoped>

</style>
