<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.settings.directSale.directBuySettings') }}</p>
    </template>

    <div class="panel">
      <div class="text-base font-semibold">
        {{ $t('companyArea.settings.directSale.directBuySettings') }}
      </div>
      <p class="text-sm text-gray-500">{{ $t('companyArea.settings.directSale.directBuySettingsInfo') }}</p>
      <p class="text-sm mt-2 text-gray-500"><span class="font-semibold text-green-600">{{ $t('general.benefits') }}:</span> {{ $t('companyArea.settings.directSale.benefits') }}</p>

      <ol class="list-decimal text-sm my-4 ml-4 text-gray-800">
        <li class="mb-1">{{ $t('companyArea.settings.directSale.step1') }}</li>
        <li class="mb-1">{{ $t('companyArea.settings.directSale.step2') }}</li>
        <li class="mb-1">{{ $t('companyArea.settings.directSale.step3') }}</li>
        <li class="mb-1">{{ $t('companyArea.settings.directSale.step4') }}</li>
      </ol>

      <div class="flex flex-wrap mt-3">
        <div v-for="classification in classifications.filter(obj => obj.name)"
             class="px-3 py-2 border rounded-md mr-3 mb-3  cursor-pointer"
             :class="{ 'bg-green-100 hover:bg-green-300':directSaleActiv(classification), 'bg-gray-100 hover:bg-gray-300':!directSaleActiv(classification), 'bg-gray-300 hover:bg-gray-300':(!directSaleActiv(classification) &&currentClassification.id === classification.id ), 'bg-green-300 hover:bg-green-300':(directSaleActiv(classification) &&currentClassification.id === classification.id ) }"
             @click="currentClassification = classification"
        >
          <div>{{ $t('classification.' + classification.name) }}</div>
          <div class="text-xs -mt-1">{{ $t(directSaleActiv(classification) ?  'companyArea.settings.directSale.directPurchaseActive' : 'companyArea.settings.directSale.directPurchaseInactive') }}</div>
        </div>
      </div>

      <div v-if="currentForm && currentForm.hasOwnProperty('id')" class="flex justify-between mt-5">
        <div>
          <button class="font-semibold">
            {{
              $t('companyArea.settings.snippets.leadFilters.directPurchaseSettings', { formName: name })
            }}
          </button>
        </div>
      </div>

      <div>
        <div v-if="formLeadFilters.length === 0"
             class="mt-3 mb-4 bg-gray-100 rounded border border-gray-200 p-3"
        >
          {{ $t('companyArea.settings.snippets.leadFilters.selectionModeInfo') }}
          <div class="flex justify-center mt-3">
            <button class="btn btn-primary btn-sm" @click="storeLeadFilter()">
              {{ $t('companyArea.settings.directSale.enableDirectBuy') }}
            </button>
          </div>
        </div>
        <confirmation-modal v-if="show.editLeadFilter"
                            :content-size="true"
                            :close-button="false"
                            @close="show.editLeadFilter = false; updateLeadFilter()"
        >
          <lead-filters v-model:lead-filter="leadFilter"
                        v-model:assistant="show.assistant"
                        :form="currentForm"
                        @close="updateLeadFilter"
          />
        </confirmation-modal>
        <div v-else-if="locations.length > 0">
          <div class="flex flex-col justify-between">
            <div class="mt-3">
              <div v-for="formLeadFilter in formLeadFilters"
                   class="mb-4 bg-gray-100 hover:bg-gray-300 rounded border border-gray-200 p-3 cursor-pointer"
                   :class="{ 'bg-green-100 hover:bg-green-100 cursor-auto':(formLeadFilter.id === leadFilter.id) }"
                   @click="leadFilter = formLeadFilter"
              >
                <div class="flex md:justify-between flex-col md:flex-row">
                  <div class="text-lg">
                    <span class="text-lg font-semibold">{{ getDistance(formLeadFilter) }}</span> -
                    <span v-if="leadFilterFieldsCount(formLeadFilter) === 0">{{ $t('companyArea.settings.snippets.leadFilters.noActiveFilters') }}</span>
                    <span v-else>{{ $t('companyArea.settings.snippets.leadFilters.filtersActive', { leadFilterFieldsCount: leadFilterFieldsCount(formLeadFilter) }) }}</span>
                  </div>
                  <div class="flex md:justify-end">
                    <button class="btn btn-sm btn-primary mr-2" @click="editLeadFilter(formLeadFilter)">
                      {{ $t('companyArea.settings.directSale.edit') }}
                    </button>
                    <button class="btn btn-sm btn-secondary" @click="deleteLeadFilter(formLeadFilter)">
                      {{ $t('companyArea.settings.directSale.delete') }}
                    </button>
                  </div>
                </div>

                <div class="mt-1 mb-2">
                <span class="font-semibold">{{ $t('companyArea.settings.directSale.conditionsDirectSale') }}</span>
                  <ul class="list-disc ml-4">
                    <li>{{ $t('companyArea.settings.directSale.location') }}</li>
                    <ul class="list-disc ml-4">
                      <li>{{ $t('companyArea.settings.directSale.needsToBeMarked') }} (<router-link to="Area" class="text-blue-500 cursor-pointer hover:text-blue-400">{{ $t('companyArea.settings.directSale.AreaSettings') }}</router-link>)</li>
                    <li v-if="formLeadFilter.distance" v-html="$t('companyArea.settings.directSale.needsToBeWithinRadius')"></li>
                    </ul>
                    <li v-if="formLeadFilter.short_term" v-html="$t('companyArea.settings.snippets.leadFilters.excludeShortTermQuotesInfo', {short_term: formLeadFilter.short_term})"></li>
                    <li v-if="formLeadFilter.long_term" v-html="$t('companyArea.settings.snippets.leadFilters.excludeLongTermQuotesInfo', {long_term: formLeadFilter.long_term})"></li>
                    <li v-if="formLeadFilter.phone">{{ $t('companyArea.settings.snippets.leadFilters.excludeNoPhoneInfo') }}</li>
                    <li v-if="formLeadFilter.price_quality">{{ $t('companyArea.settings.snippets.leadFilters.excludeCheapPrice') }}</li>
                    <component :is="field.type"
                               v-for="field in inputFields"
                               :key="field.id"
                               v-model:formdata="formdata"
                               class="relative"
                               usage="list"
                               :field="field"
                               :filters="formLeadFilter.lead_filter_fields"
                               :option-fields="field.type === 'selectField' || field.type === 'radioField' ? currentForm.fields : []"
                    />
                  </ul>
                </div>
                <div v-if="formLeadFilters.length === 1">
                  <div class="text-xs text-gray-500">
                    {{ $t('companyArea.settings.snippets.leadFilters.defineNewRadius') }}
                  </div>
                  <button class="btn btn-sm btn-secondary mt-3"
                          @click="show.addRadius = true"
                  >
                    {{
                      $t('companyArea.settings.directSale.addRadius')
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <confirmation-modal v-if="show.addRadius" @close="show.addRadius = false">
            {{ $t('companyArea.settings.directSale.radiusInfo') }}
            <input v-model="formdata.newDistance" type="number" class="border border-gray-400 w-full mt-2">
            <div class="flex justify-center mt-3">
              <button class="btn btn-md btn-primary mt-2" @click="storeLeadFilter(newDistance)">
                {{ $t('companyArea.settings.directSale.addFilter') }}
              </button>
            </div>
          </confirmation-modal>
        </div>
        <!--      <div v-else>-->
        <!--        <span v-if="formLeadFilters.length > 0" class="text-green-500 pt-3 font-semibold">{{ $t('companyArea.settings.directSale.directPurchaseActive') }}</span>-->
        <!--        <span v-else class="text-red-600 pt-3 font-semibold">{{ $t('companyArea.settings.directSale.directPurchaseInactive') }}</span>-->
        <!--        <button class="btn btn-sm btn-primary mt-2 ml-4" @click="show.panel = true">-->
        <!--          {{ $t('companyArea.settings.directSale.edit') }}-->
        <!--        </button>-->
        <!--      </div>-->

        <div v-if="locations.length > 0" class="mt-4">
          <Map :locations="locations"
               :classification="currentClassification"
               :classifications="classifications"
               :company="company"
               :calc="false"
               :radius-from-direct-filter="leadFilter ? leadFilter.distance : null"
          />
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
  import Layout from '../../Layout.vue';
  import store from '../../store/mainStore';
  import companyApi from '../../connections/company';
  import CircleButton from '../../snippets/CircleButton.vue';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import LeadFilters from './snippets/LeadFilters.vue';
  import Map from '../components/Map.vue';
import CheckboxField from './snippets/checkboxField.vue';
import SelectField from './snippets/selectField.vue';


  export default {
    name: 'DirectSale',
    components: { Map, LeadFilters, ConfirmationModal, CircleButton, Layout, CheckboxField, SelectField },
    data() {
      return {
        currentClassification: {},
        forms: [],
        leadFilters: [],
        leadFilter: {},
        locations: [],
        formdata: {
          leadFilterId: '',
          form_id: '',
          distance: '',
          newDistance: '',
          // used in selectField but not for all the backend calls
          dynamic_form: {}
        },
        show: {
          addRadius: false,
          deleteFilter: false,
          editLeadFilter: false,
          assistant: false,
        },
      };
    },
    computed: {
      classifications() {
        return store.state.company.classifications;
      },

      currentForm() {
        if (this.forms.length > 0) {
          return this.forms.find(obj => obj.classification_number === this.currentClassification.number);
        } else {
          return {};
        }
      },
      inputFields() {
        if (this.currentForm.hasOwnProperty('fields')) {
          return this.currentForm.fields.filter(obj => obj.parent === null && obj.lead_filter && (obj.type === 'checkboxField' || obj.type === 'selectField'));
        } else {
          return [];
        }
      },
      name() {
        return this.$t('classification.' + this.currentForm.translation_key);
      },
      formLeadFilters() {
        if (this.currentForm && this.currentForm.hasOwnProperty('id')) {
          return this.leadFilters
            .filter(obj => obj.form_id === this.currentForm.id)
            .sort((a, b) => a.id - b.id);
        } else {
          return [];
        }
      },
      company() {
        return store.state.company.company;
      },
    },
    created() {
      companyApi.get('companies/' + this.$route.params.company_id + '/forms/lead-filters').then(response => response.data.notificationType === 'success' ? [
        this.forms = response.data.content.company.forms,
        this.leadFilters = response.data.content.company.lead_filters] : '');
      this.currentClassification = this.classifications[0];
    },
    mounted() {
      this.locations = store.state.company.website.locations;
    },
    methods: {
      storeLeadFilter() {
        this.show.addRadius = false;
        this.formdata.form_id = this.currentForm.id;
        companyApi.post('companies/' + this.$route.params.company_id + '/forms/lead-filters', this.formdata, {
          notification: true,
          progress: true,
        }).then(response => response.data.notificationType === 'success'
          ?
          [this.leadFilters.push(response.data.content.leadFilter), this.leadFilter = response.data.content.leadFilter, this.formdata.newDistance = '', this.show.editLeadFilter = true, this.show.assistant = true]
          : '');
      },
      deleteLeadFilter(leadFilter) {
        companyApi.delete('companies/' + this.$route.params.company_id + '/forms/lead-filters/' + leadFilter.id + '/lead-filter-fields',
          { notification: true, progress: true }).then(response =>
          this.leadFilters = this.leadFilters.filter(obj => obj.id !== leadFilter.id));
      },
      editLeadFilter(leadFilter) {
        this.leadFilter = leadFilter;
        this.show.editLeadFilter = true;
        this.show.assistant = false;
      },
      updateLeadFilter() {
        this.leadFilters = this.leadFilters.filter(item => item.id !== this.leadFilter.id);
        this.leadFilters.push(this.leadFilter);
        this.show.editLeadFilter = false;
        this.show.assistant = false;
      },
      directSaleActiv(classification) {
        this.forms.find(obj => obj.classification_number === classification.number);
        return this.leadFilters.find(obj => obj.form_id === this.forms.find(obj => obj.classification_number === classification.number).id);
      },
      getDistance(leadFilter) {
        if (leadFilter.distance) {
          return leadFilter.distance + ' km';
        } else {
          return this.$t('companyArea.settings.area.wholeArea');
        }
      },
      leadFilterFieldsCount(leadFilter) {
        let count = 0;
        if (leadFilter.distance) count++;
        if (leadFilter.short_term) count++;
        if (leadFilter.long_term) count++;
        if (leadFilter.phone) count++;
        if (leadFilter.price_quality) count++;
        if (leadFilter.desired_quotes) count++;
        if (leadFilter.hasOwnProperty('lead_filter_fields')) {
          // grouping function
          let singleFilters = [];
          leadFilter.lead_filter_fields.forEach(obj => singleFilters.includes(obj.field_id) ? '' : singleFilters.push(obj.field_id));
          count += singleFilters.length;
        }
        return count;
      },

    },
    watch: {
      currentForm: {
        handler() {
          this.leadFilter = this.leadFilters.find(obj => obj.form_id === this.currentForm.id) ?? null;
        },
      },
    },
  };

</script>

<style scoped>

</style>
