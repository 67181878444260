<template>
  <div v-if="currentClassification === undefined" class="panel">
    {{ $t('companyArea.settings.area.noClassification') }}
  </div>

  <div v-else>
    <div v-if="showCountries" class="panel">
      <div class="flex justify-between">
        <div>
          <button class="text-base font-semibold" @click="showHidePanel('countries')">
            {{ $t('companyArea.settings.area.selectCountries') }}
          </button>
          <p class="text-xs text-gray-500">{{ $t('companyArea.settings.area.selectCountriesInfo') }}</p>
        </div>
        <circle-button :icon="['fas','caret-down']" size="sm" @click="showHidePanel('countries')" />
      </div>

      <p class="text-xs text-gray-500 mt-3">{{ $t('companyArea.settings.area.selectedCountries') }} </p>
      <p v-if="selected.countries.length > 10" class="font-semibold">
        {{ $t('companyArea.settings.area.selectCountriesCount', { length: selected.countries.length }) }}
      </p>
      <p v-else-if="selected.countries.length > 0" class="font-semibold">
        <span v-for="country in selected.countries"
              class="mr-3"
        >{{ country }}</span>
      </p>
      <p v-else class="font-semibold">{{ $t('companyArea.settings.area.noCountriesInfo') }}</p>

      <div v-if="panelCountries">
        <select v-model="selected.countries"
                :select-size="8"
                multiple
                class="h-40 w-full border border-gray-400 mt-4"
        >
          <option v-for="country in resources.countries" :value="country.iso2">{{ country.name }}</option>
        </select>

        <div class="flex justify-center mt-4">
          <button class="btn btn-sm btn-secondary mr-3" @click="submitAreas">{{ $t('general.save') }}</button>
          <button v-if="selected.countries.length > 0" class="btn btn-sm btn-secondary" @click="removeCountries">
            {{ $t('companyArea.settings.area.deselectAllCountries') }}
          </button>
        </div>

        <div class="flex justify-between">
          <button class="text-base font-semibold" @click="showHidePanel('countries')">
            {{ $t('companyArea.settings.area.addRegions') }}
          </button>
          <circle-button :icon="['fas','caret-down']"
                         size="sm"
                         @click="showHidePanel('continents')"
          />
        </div>

        <div v-if="panelContinents">
          <ul>
            <li v-for="option in resources.continents"
                :key="'continents' + option.iso"
                class="cursor-pointer"
                @click="addContinent(option.iso)"
            >
              {{ option.continent }}
            </li>
            <li v-for="option in resources.associations"
                :key="'association' + option.iso"
                class="cursor-pointer"
                @click="addAssociation(option.iso)"
            >
              {{ option.association }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="showRadius" class="panel">
      <div class="text-base font-semibold" @click="showHidePanel('radius')">
        {{ $t('classification.' + currentClassification.name) }}: {{ $t('companyArea.settings.area.formChangeArea') }}
      </div>
      <p class="text-xs text-gray-500">{{ $t('companyArea.settings.area.formChangeAreaInfo') }}</p>

      <ol class="list-decimal text-sm my-4 ml-4 text-gray-800">
        <li>{{ $t('companyArea.settings.area.step1') }}</li>
        <li>{{ $t('companyArea.settings.area.step2') }}</li>
        <li>{{ $t('companyArea.settings.area.step3') }}</li>
        <li>{{ $t('companyArea.settings.area.step4') }}</li>
        <li>{{ $t('companyArea.settings.area.step5') }}</li>
      </ol>

      <div class="flex flex-wrap mt-3">
        <div v-for="classification in classifications.filter(obj => obj.name)"
             class="px-3 py-2 border rounded-md mr-3 mb-3 bg-gray-100 hover:bg-gray-300 cursor-pointer"
             :class="{ 'bg-gray-300 hover:bg-gray-300':(currentClassification.id === classification.id )}"
             @click="currentClassification = classification"
        >
          {{ $t('classification.' + classification.name) }}
        </div>
      </div>

      <div class="font-semibold mt-5">{{ $t('companyArea.settings.area.locations') }}</div>
      <span v-for="location in locations" class="text-xs font-semibold mr-3">{{ location.address }}, {{
        location.city
      }} <span class="font-normal">({{
        currentClassification.radius && currentClassification.radius.find(obj => obj.id === location.id) ? currentClassification.radius.find(obj => obj.id === location.id).radius : location.radius
      }}km)</span></span>

      <div v-if="panelRadius" class="mt-4">
        <Map :locations="locations"
             :classification="currentClassification"
             :classifications="classifications"
             :company="company"
        />
      </div>
      <div v-else class="panel">
        {{ $t('companyArea.settings.area.weNeedNoArea') }}
      </div>
    </div>
  </div>
</template>

<script>
  import store from '../../store/mainStore';
  import locationApi from '../../connections/location';
  import companyApi from '../../connections/company';
  import CircleButton from '../../snippets/CircleButton.vue';
  import Map from './Map.vue';

  export default {
    name: 'AreaSetup',
    components: { Map, CircleButton },
    props: {
      register: Object,
    },
    data() {
      return {
        website: store.state.company.website,
        // By this workaround we create a clone from vuex array so it is not modified from outside in child component
        panelRadius: true,
        panelCountries: false,
        panelContinents: false,
        currentClassification: {},
        forms: [],
        leadFilters: [],
        resources: {
          continents: [],
          countries: [],
          associations: [],
        },
        selected: {
          countries: [],
        },
        showServices: false,
        changesDetected: false,
      };
    },
    computed: {
      showRadius() {
        if (this.website.loc_b2c) {
          return true;
        } else {
          return false;
        }
      },
      company() {
        return store.state.company.company;
      },
      locations() {
        let locations = JSON.parse(JSON.stringify(store.state.company.website.locations));
        return locations?.filter(obj => obj.verified_at !== null);
      },
      showCountries() {
        return false;
        if (this.website.shipping || this.website.online || this.website.loc_b2c) {
          return true;
        } else {
          return false;
        }
      },
      classifications() {
        return store.state.company.classifications;
      },
    },
    watch: {},
    beforeCreate() {
      locationApi.get('/countries/CH/areas').then(response => [this.resources.continents = response.data[0], this.resources.countries = response.data[1], this.resources.associations = response.data[2]]);
      companyApi.get('/companies/countries/websites/' + store.state.company.website.id).then(response => this.selected.countries = response.data.content);
    },
    created() {
      this.currentClassification = this.classifications[0];
    },
    methods: {
      showHidePanel(panel) {
        if (panel === 'radius') {
          this.panelRadius === true ? this.panelRadius = false : this.panelRadius = true;
        } else if (panel === 'countries') {
          this.panelCountries === true ? this.panelCountries = false : this.panelCountries = true;
        } else if (panel === 'continents') {
          this.panelContinents === true ? this.panelContinents = false : this.panelContinents = true;
        }
      },
      addContinent(option) {
        for (var item of this.resources.countries.filter(item => item.continent === option)) {
          if (!this.selected.countries.includes(item.iso2)) {
            this.selected.countries.push(item.iso2);
          }
        }
      },
      addAssociation(option) {
        for (var item of this.resources.countries.filter(item => item.association === option)) {
          this.selected.countries.push(item.iso2);
        }
      },
      removeCountries() {
        this.selected.countries = [];
      },
      submitAreas: function () {
        companyApi.post('/companies/countries/websites/' + store.state.company.website.id + '/sync', this.selected, { 'notification': true, progress: false })
          .then(response => [store.commit('company/setCompany', response.data.content.company)]);
        this.editMode = true;
      },
      formLeadFilters(form) {
        return this.leadFilters.filter(obj => obj.form_id === form.id);
      },
      getDistance(leadFilter) {
        if (leadFilter.distance) {
          return leadFilter.distance + ' km';
        } else {
          return 'Ganzes Gebiet';
        }
      },
      leadFilterFieldsCount(leadFilter) {
        let count = 0;
        if (leadFilter.short_term) count++;
        if (leadFilter.long_term) count++;
        if (leadFilter.phone) count++;
        if (leadFilter.price_quality) count++;
        if (leadFilter.desired_quotes) count++;
        if (leadFilter.hasOwnProperty('lead_filter_fields')) {
          // grouping function
          let singleFilters = [];
          leadFilter.lead_filter_fields.forEach(obj => singleFilters.includes(obj.field_id) ? '' : singleFilters.push(obj.field_id));
          count += singleFilters.length;
        }
        return count;
      },
    },
  };
</script>

<style scoped>

</style>
