<template>
  <tr>
    <StripePaymentMethodInfo :payment-method="paymentMethod" />
    <!--    Does break the Layout on md and up.. no solution yet-->
    <!--    <td class="text-xs sm:text-md hidden md:block">-->
    <!--      {{ token.expires_on ? dayjsHelper.showDate(token.expires_on) : '' }}-->
    <!--    </td>-->
    <td v-if="showButtons" class="flex justify-end">
      <text-button v-if="!paymentMethod.last4 && !paymentMethod.exp_year"
                   :icon="['fal', 'refresh']"
                   :text="$t('companyArea.accounting.credit.refresh')"
                   :hide-text-on-xs="true"
                   size="sm"
                   class="mr-2"
                   :data-cy="'btn-refresh-token'+tokenIndex"
                   @click="refreshToken"
      />
      <text-button :text="$t(paymentMethod.main === 1 ? 'companyArea.accounting.credit.isMainCard' : 'companyArea.accounting.credit.setMainCard')"
                   size="sm"
                   :hide-text-on-xs="true"
                   class="mr-2"
                   :active="paymentMethod.main === 1 ? true : false"
                   :icon="paymentMethod.main ? ['fal', 'check'] : []"
                   :data-cy="paymentMethod.main === 1 ? 'main-card-checked' : 'main-card-unchecked'"
                   @click="setToMain"
      />
      <text-button :text="$t('companyArea.accounting.credit.topUp')"
                   size="sm"
                   :hide-text-on-xs="true"
                   class="mr-2"
                   :data-cy="'btn-charge-token'+tokenIndex"
                   @click="chargeToken"
      />
      <circle-button :icon="['fal', 'trash-alt']"
                     size="sm"
                     data-cy="btn-delete-token"
                     @click="show.deleteToken = true"
      />
    </td>
  </tr>

  <transition name="modal-fade">
    <div v-if="show.deleteToken" class="absolute flex justify-center inset-0 rounded pt-3 z-50 bg-red-900/95">
      <div>
        <div class="text-center text-xl text-white font-extrabold px-2">
          <div data-cy="div-confirm-token-delete">{{ $t('companyArea.accounting.snippets.deleteToken') }}</div>
        </div>
        <div class="flex justify-center mt-3">
          <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white bg-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 mr-3"
                  data-cy="btn-confirm-token-delete"
                  @click="deleteToken"
          >
            <font-awesome-icon :icon="['fal','check']" />
            <span class="absolute -bottom-6 text-white text-xs uppercase">{{ $t('general.yes') }}</span>
          </button>
          <button class="relative w-11 h-11 flex items-center justify-center text-xl border border-white text-white rounded-full hover:bg-black hover:border-black text-black hover:text-white mb-4 ml-3"
                  data-cy="btn-cancel-token-delete"
                  @click="show.deleteToken = false"
          >
            <font-awesome-icon :icon="['fal','times']" />
            <span class="absolute -bottom-6 text-white text-xs uppercase">{{ $t('general.no') }}</span>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import companyApi from '../../../connections/company';
  import store from '../../../store/mainStore';
  import CircleButton from '../../../snippets/CircleButton.vue';
  import TextButton from '../../../snippets/TextButton.vue';
  import StripePaymentMethodInfo from './StripePaymentMethodInfo.vue';

  export default {
    name: 'StripePaymentMethodsOverview',
    components: { StripePaymentMethodInfo, CircleButton, TextButton },
    props: {
      paymentMethod: Object,
      company_id: Number,
      tokenIndex: Number,
      showButtons: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['charge-payment-method'],
    data() {
      return {
        show: {
          deleteToken: false,
        },
      };
    },
    methods: {
      deleteToken() {
        companyApi.delete('companies/' + this.company_id + '/stripe-payment-method/' + this.paymentMethod.id, {
          'progress': true,
          'notification': true,
          'notificationText': 'Zahlungsmittel wurde gelöscht.',
          'axios-retry': { retries: 0 },
        }).then(response => store.commit('company/setStripePaymentMethods', response.data.content.stripe_payment_methods));
        this.show.deleteToken = false;
      },
      setToMain() {
        companyApi.patch('companies/' + this.company_id + '/stripe-payment-method/' + this.paymentMethod.id + '/set-main', { set_main: this.paymentMethod.main !== 1 },{
          'progress': true,
          'notification': true,
        }).then(response => [store.commit('company/setStripePaymentMethods', response.data.content.stripe_payment_methods), store.commit('company/setPostfinanceTokens', response.data.content.postfinance_tokens)]);
      },
      refreshToken() {
        companyApi.patch('companies/' + this.company_id + '/stripe-payment-method/' + this.paymentMethod.id + '/refresh', {
          'progress': true,
          'notification': true,
        }).then(response => store.commit('company/setStripePaymentMethods', response.data.content.stripe_payment_methods));
      },
      chargeToken() {
        this.$emit('charge-payment-method');
      },
    },
  };
</script>

