<template>
  <div class="border p-4 mb-4 rounded grid grid-cols-2 sm:grid-cols-4 gap-1 mt-10 shadow">
    <div class="sm:col-span-2">
      <div>
        <div class="font-semibold mb-3">{{ $t('companyArea.accounting.credit.creditCard') }}</div>
        <div class="flex">
          <div class="w-10"><img src="../../../../assets/images/payment/mastercard.png"></div>
          <div class="w-12 ml-2 pt-2"><img src="../../../../assets/images/payment/visa.png"></div>
        </div>

      </div>
    </div>
    <div class="hidden sm:block">
      <div class="font-semibold mb-3">{{ $t('general.information') }}</div>
      <ul>
        <li>{{ $t('general.fees') }} 2.9%</li>
        <li>{{ $t('companyArea.accounting.credit.topUpStripeInfo1') }}</li>
        <li>{{ $t('companyArea.accounting.credit.topUpStripeInfo2') }}</li>
      </ul>
    </div>
    <div class="flex flex-col items-end">
      <div class="font-semibold mb-3">{{ $t('companyArea.accounting.credit.provider') }}</div>
      <div><img src="../../../../assets/images/payment/Stripe.png" width="90"></div>
    </div>
    <div class="col-span-2 sm:hidden mt-2">
      <div class="font-semibold">{{ $t('general.information') }}</div>
      <ul>
        <li>{{ $t('general.fees') }} 2.9%</li>
        <li>{{ $t('companyArea.accounting.credit.topUpStripeInfo1') }}</li>
        <li>{{ $t('companyArea.accounting.credit.topUpStripeInfo2') }}</li>
      </ul>
    </div>
    <div class="col-span-2 sm:col-span-4 bg-gray-100 rounded p-3 mt-4">
      <div class="flex justify-between">
        <div>
          <button class="btn btn-sm btn-primary" data-cy="topup-button"
                  @click="openForm(stripePaymentMethods.find(obj => obj.main === 1) ? stripePaymentMethods.find(obj => obj.main === 1).id : null)"
          >
            {{ $t('companyArea.accounting.credit.topUp') }}
          </button>
        </div>
        <div class="flex items-end">
          <button v-if="stripePaymentMethods.length > 0" class="btn btn-sm btn-secondary mr-2" @click="show.cards = ! show.cards">
            {{ $t('companyArea.accounting.credit.editCards') }}
          </button>
          <font-awesome-icon v-if="company.default_payment_provider === 'stripe'" class="text-green-500 text-lg"
                             :icon="['fas', 'check']"/>
          <button v-else class="btn btn-sm btn-secondary" data-cy="topup-button" @click="setDefaultPaymentProvider">
            {{ $t('companyArea.accounting.credit.setDefaultProvider') }}
          </button>
        </div>
      </div>
      <table v-if="show.cards" class="w-full mt-10">
        <stripe-payment-methods-overview v-for="stripePaymentMethod in stripePaymentMethods"
                                         :key="'card' + stripePaymentMethod.id"
                                         :company_id="company.id"
                                         :payment-method="stripePaymentMethod"
                                         @charge-payment-method="openForm(stripePaymentMethod.id)"
        />
      </table>
      <button v-if="show.cards" class="btn btn-sm btn-secondary mt-4" @click="openForm(null)">
        {{ $t('companyArea.accounting.credit.addCard')}}
      </button>
    </div>
  </div>

  <stripe-payment :show="showForm"
                  :payment-method-id="paymentMethodId"
                  @update:show="showForm = $event"
  />
</template>

<script>
  import StripePaymentMethodsOverview from './../credit/StripePaymentMethodsOverview.vue';
  import TextButton from '../../../snippets/TextButton.vue';
  import StripePayment from './StripePayment.vue';
  import companyApi from '../../../connections/company';
  import store from '../../../store/mainStore';

  export default {
    name: 'StripePanel',
    components: { StripePayment, StripePaymentMethodsOverview, TextButton },
    props: {
      company: {
        type: Object,
        default: function () {
          return {};
        },
      },
    },
    data() {
      return {
        showForm: false,
        paymentMethodId: null,
        show: {
          cards: false
        },
        formdata: {
          default_payment_provider: 'stripe'
        }
      };
    },
    computed: {
      stripePaymentMethods() {
        return this.company.stripe_payment_methods;
      },
      mainStripePaymentMethod() {
        if (this.stripePaymentMethods.length > 0) {
          return this.stripePaymentMethods.find(obj => obj.main == true);
        } else {
          return null;
        }
      },
    },
    methods: {
      openForm(paymentMethodId = null) {
        this.paymentMethodId = paymentMethodId;
        this.showForm = true;
      },
      openFormWithMainCard() {
        this.openForm(this.mainStripePaymentMethod.id);
      },
      setDefaultPaymentProvider() {
        companyApi.patch('/companies/' + store.state.company.company.id + '/default-payment-provider', this.formdata, {notification: false}).then(response => store.commit('company/setCompany', response.data.content.company)).catch(error => error.response.data.content);
      }
    },
  };
</script>
