<template>
  <div class="pb-3 mt-4 bg-gray-100 p-2">
    <div class="flex justify-between">
      <div>
        <strong>{{ review.surname.charAt(0) + '.' }} {{ review.name }},</strong> {{ review.city }} <span v-if="review.status !== 1" class="text-red-700 font-semibold">- {{ $t('sharedComponents.companyReviewsList.deleted') }}</span>
        <div class="text-sm text-gray-500 ">{{ $t('classification.' + review.classification_name.toLowerCase() ) }}</div>
        <div class="mt-2">
          <rating v-if="ratingComponent === 'Rating'" :review="review.review" class="text-xl" />
          <rating-topofferten v-else
                              :review="review.review"
                              class="w-32"
                              style="height:23px;"
          />
        </div>
      </div>
      <div class="flex flex-col items-end">
        <div class="text-sm text-gray-500">{{ this.dayjsHelper.showDate(review.created_at) }}</div>
        <div class="flex items-end">
          <circle-button v-if="area === 'Company'"
                         :icon="['fal','pen']"
                         class="mt-4 mr-2"
                         :class="{ 'active':(show.comment)}"
                         size="sm"
                         @click="show.comment = ! show.comment"
          />
        </div>
      </div>
    </div>
    <div v-if="show.details" class="inline-block mt-3 mb-3">
      <div class="flex justify-between">
        <div>{{ $t('sharedComponents.companyReviewsList.priceCompliance') }}</div>
        <rating v-if="ratingComponent === 'Rating'" :review="review.review_1" class="text-base ml-4" />
        <rating-topofferten v-else
                            :review="review.review_1"
                            class="w-24 ml-4"
                            style="height:17px;"
        />
      </div>
      <div class="flex justify-between">
        <div>{{ $t('sharedComponents.companyReviewsList.jobQuality') }}</div>
        <rating v-if="ratingComponent === 'Rating'" :review="review.review_3" class="text-base ml-4" />
        <rating-topofferten v-else
                            :review="review.review_2"
                            class="w-24 ml-4"
                            style="height:17px;"
        />
      </div>
      <div class="flex justify-between">
        <div>{{ $t('sharedComponents.companyReviewsList.administration') }}</div>
        <rating v-if="ratingComponent === 'Rating'" :review="review.review_2" class="text-base ml-4" />
        <rating-topofferten v-else
                            :review="review.review_3"
                            class="w-24 ml-4"
                            style="height:17px;"
        />
      </div>
    </div>
    <p v-if="review.text && review.text.length > 0" class=" text-sm mt-3 bg-yellow-50 p-2 rounded-sm shadow">{{ review.text }}</p>
    <div v-if="show.comment">
      <textarea id="textarea"
                v-model="formdata.company_comment"
                :placeholder="$t('sharedComponents.companyReviewsList.yourResponse')"
                rows="3"
                class="w-full border border-gray-400 mt-2"
                data-cy="review-input-text"
      />
      <div class="flex justify-center mt-2">
        <button class="btn btn-md btn-primary" @click="storeCompanyComment">{{ $t('general.submit') }}</button>
      </div>
    </div>
    <p v-else-if="review.company_comment" class="text-sm bg-gray-100 p-2 mt-2">
      <span class="font-semibold">{{ $t(area === 'Company' ? 'sharedComponents.companyReviewsList.yourResponse' : 'sharedComponents.companyReviewsList.companyResponse') }}</span><br>
      {{ review.company_comment }}
    </p>
  </div>
</template>

<script>
import RatingTopofferten from '../snippets/RatingTopofferten.vue';
import CircleButton from '../snippets/CircleButton.vue';
import companyApi from '../connections/company';
import store from '../store/mainStore';
import Rating from "../snippets/Rating.vue";

export default {
  name: 'CompanyReviewsList',
  components: {Rating, CircleButton, RatingTopofferten },
  props: {
    review: Object,
  },
  emits: ['get-height'],
  data() {
    return {
      formdata: {
        company_comment: JSON.parse(JSON.stringify(this.review.company_comment)),
      },
      area: this.$route.meta.area,
      show: {
        details: true,
        comment: false,
      },
        ratingComponent: import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'vipany' ? 'Rating' : 'RatingTopofferten',
    };
  },
  methods: {
    storeCompanyComment() {
      companyApi.patch('company-reviews/' + this.review.id + '/company-comment', this.formdata, { 'progress':true, 'notification':true }).then(response => [store.commit('company/setCompany', response.data.content.company), this.show.comment = false])
    },
    showDetails() {
      if(this.area === 'Customer') {
        this.$emit('get-height')
      }
      this.show.details = ! this.show.details
    }
  }
};
</script>

<style scoped>

</style>
