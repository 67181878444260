<template>
  <div class="border mt-5 p-4 mb-4 rounded grid grid-cols-2 sm:grid-cols-4 gap-1 shadow">
    <div class="sm:col-span-2">
      <div class="font-semibold mb-3">{{ $t('companyArea.accounting.credit.creditCard') }} / Twint</div>
      <div class="flex">
        <div class="w-10"><img src="../../../../assets/images/payment/mastercard.png"></div>
        <div class="w-12 ml-2 pt-2"><img src="../../../../assets/images/payment/visa.png"></div>
        <div class="w-20"><img src="../../../../assets/images/payment/twint.png"></div>
      </div>

    </div>
    <div class="hidden sm:block">
      <div class="font-semibold mb-3">{{ $t('general.information') }}</div>
      <ul>
        <li>{{ $t('general.fees') }} 1.3%</li>
      </ul>
    </div>
    <div class="flex flex-col items-end">
      <div class="font-semibold mb-3">{{ $t('companyArea.accounting.credit.provider') }}</div>
      <div class="sm:mb-10"><img src="../../../../assets/images/payment/postfinance.png" width="100"></div>
    </div>
    <div class="col-span-2 sm:hidden mt-2">
      <div class="font-semibold">{{ $t('general.information') }}</div>
      <ul>
        <li>{{ $t('general.fees') }} 1.3%</li>
      </ul>
    </div>
    <div class="col-span-2 sm:col-span-4 bg-gray-100 rounded p-3 mt-4 flex justify-between">
      <div>
        <button class="btn btn-sm btn-primary" data-cy="topup-button" @click="charge">
          {{ $t('companyArea.accounting.credit.topUp') }}
        </button>
      </div>
      <div>
        <font-awesome-icon v-if="company.default_payment_provider === 'postfinance'" class="text-green-500 text-lg"
                           :icon="['fas', 'check']"/>
        <button v-else class="btn btn-sm btn-secondary" data-cy="topup-button" @click="setDefaultPaymentProvider">
          {{ $t('companyArea.accounting.credit.setDefaultProvider') }}
        </button>
      </div>
    </div>
  </div>

    <postfinance-payment :show="showPostfinanceForm" @update:show="showPostfinanceForm = $event"/>

</template>
<script>
  import store from '../../../store/mainStore';
  import PostfinancePayment from './PostfinancePayment.vue';
  import companyApi from '../../../connections/company';

  export default  {
    name: 'PostfinancePanel',
    components: { PostfinancePayment },
    props: {
      company: {},
    },
    emits: ['charge-with-main-card'],
    data() {
      return {
        showPostfinanceForm: false,
        formdata: {
          default_payment_provider: 'postfinance'
        }
      }
    },
    methods: {

      charge() {
          this.showPostfinanceForm = true;

      },
      openAdminForm(type) {
        this.formAdmin.transferType = type;
        this.formAdmin.show = true;
      },
      setDefaultPaymentProvider() {
        companyApi.patch('/companies/' + store.state.company.company.id + '/default-payment-provider', this.formdata, {notification: false}).then(response => store.commit('company/setCompany', response.data.content.company)).catch(error => error.response.data.content);
      }
    }
  };
</script>
