<template>
  <div v-if="makeReview(true)" class="flex justify-center mt-3 mb-6">
    <button class="btn btn-lg btn-primary" @click="$router.push({ name: 'MakeReview', params: { lead_uuid: companyLead.lead_uuid, customer_company_id: companyLead.company_id } })">{{ $t(editReview ? 'customerArea.companyFocus.reviews.editFeedback' : 'customerArea.companyFocus.reviews.writeFeedback') }}</button>
  </div>
  <div v-if="company.company_reviews.length === 0" class="text-center mt-10 font-semibold">
    {{ $t('customerArea.companyFocus.reviews.noReviewsYet') }}
  </div>
  <div v-else>
    <company-reviews-list v-for="review in company.company_reviews.slice(0, reviewAmount)" :review="review" @get-height="$emit('get-height', false)" />
    <div class="flex justify-center mt-6 mb-3">
      <button v-if="reviewAmount < company.company_reviews.length" class="btn btn-lg btn-primary sm:hidden mr-1" @click="reviewAmount = reviewAmount + 5">{{ $t('customerArea.companyFocus.reviews.moreReviews') }}</button>
      <button v-if="reviewAmount < company.company_reviews.length" class="btn btn-lg btn-primary hidden sm:block mr-1" @click="moreReviews">{{ $t('customerArea.companyFocus.reviews.moreReviews') }}</button>
    </div>
  </div>
</template>

<script>
import CompanyReviewsList from '../../sharedComponents/CompanyReviewsList.vue';
import { customerMixin} from '../../../plugins/mixin';
import store from '../../store/mainStore';

export default {
  name: 'Reviews',
  components: { CompanyReviewsList },
  mixins: [ customerMixin ],
  props: {
    companyLead: {
      type: Object,
      default: () => ({})
    },
    company: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['get-height','close-reviews'],
  data() {
    return {
      reviewAmount: 5,
    };
  },
  methods: {
    moreReviews() {
      this.reviewAmount = this.reviewAmount + 5;
      this.$emit('get-height');
    },
  },
  computed: {
    editReview() {
      let review = store.state.customer.userReviews.find(obj => obj.company_id === this.companyLead.company_id && obj.lead_id === this.companyLead.lead_id );
      return review?.id ? true : false
    }
  }
};
</script>

<style scoped>

</style>
