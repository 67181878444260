<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10" data-cy="header-title">{{ $t('companyArea.accounting.credit.saldo') }}</p>
    </template>

    <CompanyBalance v-if="user.is_admin && $route.query.admin !== 'false'" :company="company" :user="user"/>

    <div class="panel">
      <div class="text-base font-semibold" data-cy="balance-label">{{ $t('companyArea.accounting.credit.currentSaldo') }}: <span :class="{ 'text-red-700':(company.credit < 0) }"
                                                                                                                                 data-cy="balance-amount"
      ><strong>{{ company.currency }} {{ company.credit }}</strong></span></div>
      <p v-if="company.credit_limit > 0" class="mt-2">{{ $t('companyArea.accounting.credit.yourCreditLimit', { currency: company.currency, credit_limit: company.credit_limit }) }}</p>
      <p v-if="company.credit_red_at" class="mt-2">
      <span v-if="30 - dayjsHelper.countDaysSinceDate(company.credit_red_at) > 0"
            v-html="$t('companyArea.accounting.credit.saldoMinusAllowed', { countDaysSinceDate: (30 - dayjsHelper.countDaysSinceDate(company.credit_red_at))})"
      />
        <span v-else>{{ $t('companyArea.accounting.credit.endMinus') }}</span>
      </p>

      <PostfinancePanel ref="postfinancePanel" :company="company" />
      <StripePanel ref="stripePanel" :company="company"/>

      <ul class="list-disc ml-4 text-gray-800 text-sm mt-10">
        <li>{{ $t('companyArea.accounting.credit.directSaleInfo') }}</li>
        <li>{{ $t('companyArea.accounting.credit.addCardInfo') }}</li>
        <li v-html="$t('companyArea.accounting.credit.bankTransferWithourFees')"></li>
      </ul>
    </div>

    <AutomaticRefill :company="company"/>
    <BankAccountInfo :company="company"/>
  </layout>
</template>

<script>
  import store from '../../store/mainStore';
  import Layout from '../../Layout.vue';
  import notification from '../../connections/notification';
  import CompanyBalance from './credit/CompanyBalance.vue';
  import StripePanel from './credit/StripePanel.vue';
  import PostfinancePanel from './credit/PostfinancePanel.vue';
  import BankAccountInfo from './credit/BankAccountInfo.vue';
  import AutomaticRefill from './credit/AutomaticRefill.vue';
  import DatatransPanel from './credit/DatatransPanel.vue';

  export default {
    name: 'Credit',
    components: { DatatransPanel, AutomaticRefill, BankAccountInfo, StripePanel, CompanyBalance, Layout, PostfinancePanel },
    data() {
      return {
        user: store.state.main.user,
        showStripeForm: false,
        showPostfinanceForm: false,
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
      stripePaymentMethods() {
        return this.company.stripe_payment_methods;
      },
      showDatatransPanel() {
        return this.$route.query.show_datatrans || this.$route.query.datatrans;
      },
    },
    methods: {
      chargeWithMainCard() {
        // if (this.company.postfinance_tokens.length > 0 && (this.company.postfinance_tokens.find(obj => obj.main == true))) {
        //   this.$refs.postfinancePanel.openFormWithMainCard();
        if (this.company.stripe_payment_methods.length > 0 && (this.company.stripe_payment_methods.find(obj => obj.main == true))) {
          this.$refs.stripePanel.openFormWithMainCard();
        } else {
          notification.warning(this.$t('companyArea.accounting.credit.noCardFound'));
        }
      },
    },
  };
</script>

